import { storeToRefs } from 'pinia'
import { useCommonApi } from '~/api/common'
import { useAppStore } from '~/store/app'
export const useListBank = () => {
  const { $axios, $pinia } = useNuxtApp()
  const { LIST_BANK } = useCommonApi()
  const listBanks = ref<any[]>([])
  const store = useAppStore($pinia)
  const { isLogged } = storeToRefs(store)
  const fetchListBank = async () => {
    if (!isLogged.value) {
      return
    }
    try {
      const { data: response } = await $axios.get(LIST_BANK)
      if (response && response.status === 'OK') {
        listBanks.value = response.data
        store.setListBanks(listBanks.value)
      }
    } catch (error) {
      console.log(error)
    }
    return []
  }

  return {
    fetchListBank,
    listBanks
  }
}
