import { useLobbyGameApi } from '@/api/lobby-game'
import { useAppStore } from '@/store/app'

export const useJackpot = () => {
  const { setJackpot, setSumJackpot } = useAppStore()
  const { JACKPOT_EVENT } = useLobbyGameApi()
  let eventSource: EventSource
  const startJackpotEvent = () => {
    if (EventSource) {
      eventSource = new EventSource(JACKPOT_EVENT)
      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data)
          setJackpot(data?.jackpotData)
          setSumJackpot(data?.sumjackpot)
        } catch (error) {
          console.error(error)
        }
      }
    } else {
      console.error("Your browser doesn't support SSE")
    }
  }

  return {
    startJackpotEvent
  }
}
